* {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
}

body {
  margin: 40px;
}

body {
  margin: 40px;
}
