.wrapper {
  display: grid;
  grid-gap: 15px;
  grid-template-columns: 200px 120px 120px;
  grid-template-areas:
    ". header  header"
    "sidebar content content";
  background-color: #fff;
  color: #444;
}
